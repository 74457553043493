/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { Button } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DataGrid, GridColDef, GridRowData } from '@mui/x-data-grid';
import { navigate } from 'gatsby';

import CancelButton from '../../components/Button/CancelButton';
import SubmitButton from '../../components/Button/SubmitButton';
import TextField from '../../components/TextField';
import { loadingEndAction, loadingStartAction } from '../../reducers/loadingReducer';
import { showNotificationAction } from '../../reducers/notificationReducer';
import { mutate } from '../../utils/graphql';
import { JobRequestStatusEnum } from './JobRequestListView';

const JobRequestDetailView = ({ jobRequest }: { jobRequest: any }): any => {
  const dispatch = useDispatch();
  const [jobRequestInfo, setJobRequestInfo] = useState<any>({});
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [denyDisabledButton, setDenyDisabledButton] = useState<boolean>(true);
  const [comments, setComments] = useState<GridRowData[]>([]);

  const GET_JOB_REQUEST_BY_ID = gql`
    query QUERY($id: String!) {
      getCompanyJobRequestById(id: $id) {
        id
        status
        comment
        basicInfo {
          city
          description
          nationality
          province
          region
          title
          requestConsultantNumber
          workingAddress
          startTime
          endTime
        }
        detail {
          workType
          duration
          expectStartTime
          experience
          settlePercent
          workPlace
          salaryMax
          salaryMin
        }
        company {
          companyName
          contact
          city
          description
          industry
          province
          registrationAddress
          owner {
            email
          }
        }
      }
    }
  `;
  const { data: jobRequestData, loading: jobRequestLoading } = useQuery(GET_JOB_REQUEST_BY_ID, {
    variables: {
      id: jobRequest,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    // if (!consultantLoading) {
    //   dispatch(loadingStartAction());
    // }
    // if (consultantError) {
    //   dispatch(loadingEndAction());
    // }
    if (jobRequestData && jobRequestData.getCompanyJobRequestById) {
      setJobRequestInfo(jobRequestData.getCompanyJobRequestById);
    }
  }, [jobRequestData, jobRequestLoading]);

  const APPROVE_JOB_REQUEST = gql`
    mutation MUTATION($id: String) {
      approveJobRequest(id: $id) {
        status
      }
    }
  `;

  const DENY_JOB_REQUEST = gql`
    mutation MUTATION($id: String, $comment: String) {
      denyJobRequest(id: $id, comment: $comment) {
        status
        comment
      }
    }
  `;

  const handleApprove: MouseEventHandler<HTMLButtonElement> = async e => {
    dispatch(loadingStartAction());
    const { data, error, loading } = await mutate(
      APPROVE_JOB_REQUEST,
      { id: jobRequest },
      {
        fetchPolicy: 'no-cache',
      }
    );
    if (!loading) {
      dispatch(loadingEndAction());
    }
    if (error) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '获批顾问审核申请失败',
        })
      );
    }
    if (data && data.approveJobRequest) {
      e.preventDefault();
      dispatch(
        showNotificationAction({
          severity: 'success',
          message: '获批顾问审核申请成功',
        })
      );
      setDisabledButton(true);
    }
  };

  const [comment, setComment] = useState<string>(jobRequest.comment || '');

  const handleCommentChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
    setComment(e.target.value);
  };

  const handleDeny: MouseEventHandler<HTMLButtonElement> = async e => {
    dispatch(loadingStartAction());
    const { data, error, loading } = await mutate(
      DENY_JOB_REQUEST,
      { id: jobRequest, comment: comment },
      {
        fetchPolicy: 'no-cache',
      }
    );
    if (!loading) {
      dispatch(loadingEndAction());
    }
    if (error) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '获批顾问审核申请失败',
        })
      );
    }
    if (data && data.denyJobRequest) {
      e.preventDefault();
      dispatch(
        showNotificationAction({
          severity: 'success',
          message: '获批顾问审核申请成功',
        })
      );
      setDisabledButton(true);
    }
  };

  const GET_ALL_COMMENTS = gql`
    query QUERY($jobRequestId: String!) {
      getAllJobRequestComments(jobRequestId: $jobRequestId) {
        id
        time
        comment
      }
    }
  `;

  const { data: allComments } = useQuery(GET_ALL_COMMENTS, {
    variables: {
      jobRequestId: jobRequest,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (allComments && allComments.getAllJobRequestComments) {
      setComments(
        allComments.getAllJobRequestComments.map((c: any) => ({
          id: c.id,
          time: c.time,
          comment: c.comment,
        }))
      );
    }
  }, [allComments]);

  useEffect(() => {
    if (jobRequestInfo.status === 'ACCEPTED') {
      setDisabledButton(true);
    }
    if (jobRequestInfo.status === 'DENIED') {
      setDisabledButton(true);
    }
  }, [jobRequestInfo.status]);

  useEffect(() => {
    if (comment !== '') {
      setDenyDisabledButton(false);
    } else {
      setDenyDisabledButton(true);
    }
  }, [comment]);

  const columns: GridColDef[] = [
    {
      headerName: '评论',
      field: 'comment',
      width: 350,
    },
    {
      headerName: '时间',
      field: 'time',
      width: 200,
    },
  ];

  return (
    <div style={{ paddingLeft: '10%', paddingRight: '10%', paddingTop: '2%', paddingBottom: '2%' }}>
      <Paper elevation={3}>
        <Button
          onClick={() => {
            navigate('/admin/jobRequest');
          }}
        >
          <ArrowBackIcon />
        </Button>
        <DialogTitle> {jobRequestInfo.basicInfo?.title} </DialogTitle>
        <div style={{ paddingLeft: '2%', paddingRight: '2%' }}>
          <Typography style={{ color: 'gray', fontSize: '90%' }}>
            状态: {(JobRequestStatusEnum as any)[jobRequestInfo.status || 'NONE']}
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>信息</TableCell>
                <TableCell>细节</TableCell>
                <TableCell>公司</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>国籍: {jobRequestInfo.basicInfo?.nationality}</TableCell>
                <TableCell>工作类型: {jobRequestInfo.detail?.workType}</TableCell>
                <TableCell>公司名称: {jobRequestInfo.company?.companyName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  地点: {jobRequestInfo.basicInfo?.province}，{jobRequestInfo.basicInfo?.region}，
                  {jobRequestInfo.basicInfo?.city}
                </TableCell>
                <TableCell>工作地点: {jobRequestInfo.detail?.workPlace}</TableCell>
                <TableCell>接触: {jobRequestInfo.company?.contact}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>工作地址: {jobRequestInfo.basicInfo?.workingAddress}</TableCell>
                <TableCell>预计开始时间: {jobRequestInfo.detail?.expectStartTime}</TableCell>
                <TableCell>
                  地点: {jobRequestInfo.company?.province}，{jobRequestInfo.company?.city}，
                  {jobRequestInfo.company?.registrationAddress}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  索取顾问编号: {jobRequestInfo.basicInfo?.requestConsultantNumber}
                </TableCell>
                <TableCell>期间: {jobRequestInfo.detail?.duration}</TableCell>
                <TableCell>行业: {jobRequestInfo.company?.industry}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>开始时间: {jobRequestInfo.basicInfo?.startTime}</TableCell>
                <TableCell>结算百分比: {jobRequestInfo.detail?.settlePercent}</TableCell>
                <TableCell>联系电子邮件: {jobRequestInfo.company?.owner.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>时间结束: {jobRequestInfo.basicInfo?.endTime}</TableCell>
                <TableCell>经验: {jobRequestInfo.detail?.experience}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>预计最低酬劳: {jobRequestInfo.detail?.salaryMin}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>描述: {jobRequestInfo.basicInfo?.description}</TableCell>
                <TableCell>最高工资: {jobRequestInfo.detail?.salaryMax}</TableCell>
                <TableCell>描述: {jobRequestInfo.company?.description}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {jobRequestInfo.status === 'DENIED' && (
            <div style={{ paddingTop: '1.5%' }}>
              <>
                <br />
                <Typography style={{ color: 'grey' }}>验证评论</Typography>
                <div style={{ height: 300 }}>
                  <DataGrid rows={comments} columns={columns} />
                </div>
              </>
              <Typography variant="h6">审核备注</Typography>
              <Typography>{jobRequestInfo.comment || ''}</Typography>
            </div>
          )}
          {jobRequestInfo.status === 'APPLIED' && (
            <>
              <>
                <br />
                <Typography style={{ color: 'grey' }}>验证评论</Typography>
                <div style={{ height: 300 }}>
                  <DataGrid rows={comments} columns={columns} />
                </div>
              </>
              <TextField
                label="审核备注"
                value={comment}
                onChange={handleCommentChange}
                disabled={disabledButton}
                autoFocus={false}
              />
              <DialogActions>
                <CancelButton
                  onClick={handleDeny as MouseEventHandler}
                  disabled={disabledButton || denyDisabledButton}
                >
                  拒绝
                </CancelButton>
                <SubmitButton
                  onClick={handleApprove as MouseEventHandler}
                  disabled={disabledButton}
                >
                  批准
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default JobRequestDetailView;
